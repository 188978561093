import CustomDatePicker from '@/app/customComponents/CustomDatePicker/CustomDatePicker';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	Drawer,
	FormControl,
	makeStyles,
	MenuItem,
	Select,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { defaultHistoryTimes } from './constants';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles({
	layoutRoot: {},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select.MuiSelect-select': {
			textAlign: 'left',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
	dateStyle: {
		'& .MuiInput-root::after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover::before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			cursor: 'pointer',
		},
		'& .MuiInput-root': {
			cursor: 'pointer',
		},
	},
	accordionSummaryStyle: {
		background: 'radial-gradient(circle, rgba(255, 255, 255, 1) 46%, rgba(235, 247, 254, 1) 68%)',
	},
});

const HistoryDrawer = ({
	roomData,
	open,
	setOpen,
	showFixedDrawer,
	rooms,
	selectedDate,
	setDate,
	isLoading,
	activeHourSlot,
	selectedClip,
	handleHourClick,
	handleClipSelect,
	setActiveHourSlot,
	handleRoomChange,
	setStreamUrl,
	setSelectedClip,
}) => {
	const classes = useStyles();
	const handleToggleDrawer = () => {
		setOpen(!open);
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};
	return (
		<Drawer
			variant={showFixedDrawer ? 'permanent' : 'temporary'}
			anchor="right"
			classes={{
				paper: showFixedDrawer ? 'pt-160 px-16 ' : 'px-16 pt-32',
			}}
			PaperProps={{
				style: {
					background: 'linear-gradient(130deg, rgba(244, 249, 255, 1) 0%, rgba(208, 226, 244, 1) 100%)',
				},
			}}
			open={open}
			onClose={handleToggleDrawer}
		>
			{!showFixedDrawer && (
				<div className="absolute top-16 right-16 w-16 cursor-pointer">
					<Close onClick={handleToggleDrawer} />
				</div>
			)}
			<div className="sticky top-0 z-10">
				<div className="mb-16">
					<h2 className="font-bold mb-10" style={{ fontSize: '18px' }}>
						Room
					</h2>
					<div className="rounded-full border  border-pd-blue">
						<FormControl className="px-10 w-full relative">
							<Select
								className={classes.select}
								inputProps={{
									classes: {
										root: classes.root,
										icon: classes.icon,
									},
								}}
								name="isRoom"
								value={roomData}
								id="isRoom"
								onChange={handleRoomChange}
								MenuProps={MenuProps}
							>
								{rooms.length ? (
									rooms.map((room) => (
										<MenuItem key={room.id} value={room}>
											<div className="menu-item" id={room.id}>
												{room.name}
											</div>
										</MenuItem>
									))
								) : (
									<MenuItem>Loading...</MenuItem>
								)}
							</Select>
						</FormControl>
					</div>
				</div>

				<div className="mb-16 border-b pb-16">
					<h2 className="font-bold mb-10" style={{ fontSize: '18px' }}>
						Date
					</h2>
					<div className="border-1 border-pd-blue rounded-full">
						<CustomDatePicker
							dateStyle={classes.dateStyle}
							id="date-from"
							paddingLeft="11px"
							paddingRight="11px"
							value={selectedDate}
							setValue={(d) => {
								setDate(d?.format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'));
								setStreamUrl(null);
								setSelectedClip(null);
								setActiveHourSlot(null);
							}}
							disableFuture
						/>
					</div>
				</div>
			</div>

			<div className={clsx(classes.divider)} />

			{isLoading ? (
				<div className="flex justify-center mt-16">
					<CircularProgress size={35} />
				</div>
			) : (
				<div className="max-h-full overflow-y-auto">
					{defaultHistoryTimes &&
						defaultHistoryTimes?.map((item, index) => (
							<Accordion key={`${item?.id}-${index}`} expanded={item?.id === activeHourSlot?.id}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									onClick={() => handleHourClick(item)}
									IconButtonProps={{
										className: 'pl-0 pt-6 hover:bg-transparent',
										disableRipple: true,
									}}
									className={classes.accordionSummaryStyle}
								>
									<span className="font-bold">
										{dayjs(`${selectedDate}${item?.start_time}`).format('h:mm A')} -{' '}
										{dayjs(`${selectedDate}${item?.end_time}`).format('h:mm A')}
									</span>
								</AccordionSummary>
								<AccordionDetails
									className="pb-10 flex justify-center border-t"
									style={{
										background:
											'radial-gradient(circle, rgba(255, 255, 255, 1) 46%, rgba(235, 247, 254, 1) 68%)',
									}}
								>
									<div className="flex flex-col w-full gap-10 justify-center">
										{item?.timings?.map((time, index) => {
											const isSelected = time?.id === selectedClip?.id;
											return (
												<div
													key={index}
													onClick={() => handleClipSelect(time)}
													className="flex justify-between items-center gap-6 w-full p-6 hover:bg-white/70 rounded-md cursor-pointer"
												>
													<img src="assets/images/Group 86539.png" alt="thumbnail" />
													<span
														className={`flex items-center ${
															isSelected ? 'font-semibold' : ''
														}`}
													>
														{dayjs(`${selectedDate}${time?.start_time}`).format('h:mm A')} -{' '}
														{dayjs(`${selectedDate}${time?.end_time}`).format('h:mm A')}
													</span>
													<div
														className="text-white flex items-center justify-center border-1 border-pd-blue rounded-full h-20 text-center w-20"
														style={{
															border: '1px #4da0ee solid',
															background: isSelected ? '#4DA0EE' : 'white',
														}}
													>
														<Check className="w-16" />
													</div>
												</div>
											);
										})}
									</div>
								</AccordionDetails>
							</Accordion>
						))}
				</div>
			)}
		</Drawer>
	);
};

export default HistoryDrawer;

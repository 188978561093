export const defaultHistoryTimes = [
	{
		id: 0,
		start_time: '00:00:00',
		end_time: '01:00:00',
		timings: [
			{ id: 0, start_time: '00:00:00', end_time: '00:20:00' },
			{ id: 1, start_time: '00:20:00', end_time: '00:40:00' },
			{ id: 2, start_time: '00:40:00', end_time: '01:00:00' },
		],
	},
	{
		id: 1,
		start_time: '01:00:00',
		end_time: '02:00:00',
		timings: [
			{ id: 0, start_time: '01:00:00', end_time: '01:20:00' },
			{ id: 1, start_time: '01:20:00', end_time: '01:40:00' },
			{ id: 2, start_time: '01:40:00', end_time: '02:00:00' },
		],
	},
	{
		id: 2,
		start_time: '02:00:00',
		end_time: '03:00:00',
		timings: [
			{ id: 0, start_time: '02:00:00', end_time: '02:20:00' },
			{ id: 1, start_time: '02:20:00', end_time: '02:40:00' },
			{ id: 2, start_time: '02:40:00', end_time: '03:00:00' },
		],
	},
	{
		id: 3,
		start_time: '03:00:00',
		end_time: '04:00:00',
		timings: [
			{ id: 0, start_time: '03:00:00', end_time: '03:20:00' },
			{ id: 1, start_time: '03:20:00', end_time: '03:40:00' },
			{ id: 2, start_time: '03:40:00', end_time: '04:00:00' },
		],
	},
	{
		id: 4,
		start_time: '04:00:00',
		end_time: '05:00:00',
		timings: [
			{ id: 0, start_time: '04:00:00', end_time: '04:20:00' },
			{ id: 1, start_time: '04:20:00', end_time: '04:40:00' },
			{ id: 2, start_time: '04:40:00', end_time: '05:00:00' },
		],
	},
	{
		id: 5,
		start_time: '05:00:00',
		end_time: '06:00:00',
		timings: [
			{ id: 0, start_time: '05:00:00', end_time: '05:20:00' },
			{ id: 1, start_time: '05:20:00', end_time: '05:40:00' },
			{ id: 2, start_time: '05:40:00', end_time: '06:00:00' },
		],
	},
	{
		id: 6,
		start_time: '06:00:00',
		end_time: '07:00:00',
		timings: [
			{ id: 0, start_time: '06:00:00', end_time: '06:20:00' },
			{ id: 1, start_time: '06:20:00', end_time: '06:40:00' },
			{ id: 2, start_time: '06:40:00', end_time: '07:00:00' },
		],
	},
	{
		id: 7,
		start_time: '07:00:00',
		end_time: '08:00:00',
		timings: [
			{ id: 0, start_time: '07:00:00', end_time: '07:20:00' },
			{ id: 1, start_time: '07:20:00', end_time: '07:40:00' },
			{ id: 2, start_time: '07:40:00', end_time: '08:00:00' },
		],
	},
	{
		id: 8,
		start_time: '08:00:00',
		end_time: '09:00:00',
		timings: [
			{ id: 0, start_time: '08:00:00', end_time: '08:20:00' },
			{ id: 1, start_time: '08:20:00', end_time: '08:40:00' },
			{ id: 2, start_time: '08:40:00', end_time: '09:00:00' },
		],
	},
	{
		id: 9,
		start_time: '09:00:00',
		end_time: '10:00:00',
		timings: [
			{ id: 0, start_time: '09:00:00', end_time: '09:20:00' },
			{ id: 1, start_time: '09:20:00', end_time: '09:40:00' },
			{ id: 2, start_time: '09:40:00', end_time: '10:00:00' },
		],
	},
	{
		id: 10,
		start_time: '10:00:00',
		end_time: '11:00:00',
		timings: [
			{ id: 0, start_time: '10:00:00', end_time: '10:20:00' },
			{ id: 1, start_time: '10:20:00', end_time: '10:40:00' },
			{ id: 2, start_time: '10:40:00', end_time: '11:00:00' },
		],
	},
	{
		id: 11,
		start_time: '11:00:00',
		end_time: '12:00:00',
		timings: [
			{ id: 0, start_time: '11:00:00', end_time: '11:20:00' },
			{ id: 1, start_time: '11:20:00', end_time: '11:40:00' },
			{ id: 2, start_time: '11:40:00', end_time: '12:00:00' },
		],
	},
	{
		id: 12,
		start_time: '12:00:00',
		end_time: '13:00:00',
		timings: [
			{ id: 0, start_time: '12:00:00', end_time: '12:20:00' },
			{ id: 1, start_time: '12:20:00', end_time: '12:40:00' },
			{ id: 2, start_time: '12:40:00', end_time: '13:00:00' },
		],
	},
	{
		id: 13,
		start_time: '13:00:00',
		end_time: '14:00:00',
		timings: [
			{ id: 0, start_time: '13:00:00', end_time: '13:20:00' },
			{ id: 1, start_time: '13:20:00', end_time: '13:40:00' },
			{ id: 2, start_time: '13:40:00', end_time: '14:00:00' },
		],
	},
	{
		id: 14,
		start_time: '14:00:00',
		end_time: '15:00:00',
		timings: [
			{ id: 0, start_time: '14:00:00', end_time: '14:20:00' },
			{ id: 1, start_time: '14:20:00', end_time: '14:40:00' },
			{ id: 2, start_time: '14:40:00', end_time: '15:00:00' },
		],
	},
	{
		id: 15,
		start_time: '15:00:00',
		end_time: '16:00:00',
		timings: [
			{ id: 0, start_time: '15:00:00', end_time: '15:20:00' },
			{ id: 1, start_time: '15:20:00', end_time: '15:40:00' },
			{ id: 2, start_time: '15:40:00', end_time: '16:00:00' },
		],
	},
	{
		id: 16,
		start_time: '16:00:00',
		end_time: '17:00:00',
		timings: [
			{ id: 0, start_time: '16:00:00', end_time: '16:20:00' },
			{ id: 1, start_time: '16:20:00', end_time: '16:40:00' },
			{ id: 2, start_time: '16:40:00', end_time: '17:00:00' },
		],
	},
	{
		id: 17,
		start_time: '17:00:00',
		end_time: '18:00:00',
		timings: [
			{ id: 0, start_time: '17:00:00', end_time: '17:20:00' },
			{ id: 1, start_time: '17:20:00', end_time: '17:40:00' },
			{ id: 2, start_time: '17:40:00', end_time: '18:00:00' },
		],
	},
	{
		id: 18,
		start_time: '18:00:00',
		end_time: '19:00:00',
		timings: [
			{ id: 0, start_time: '18:00:00', end_time: '18:20:00' },
			{ id: 1, start_time: '18:20:00', end_time: '18:40:00' },
			{ id: 2, start_time: '18:40:00', end_time: '19:00:00' },
		],
	},
	{
		id: 19,
		start_time: '19:00:00',
		end_time: '20:00:00',
		timings: [
			{ id: 0, start_time: '19:00:00', end_time: '19:20:00' },
			{ id: 1, start_time: '19:20:00', end_time: '19:40:00' },
			{ id: 2, start_time: '19:40:00', end_time: '20:00:00' },
		],
	},
	{
		id: 20,
		start_time: '20:00:00',
		end_time: '21:00:00',
		timings: [
			{ id: 0, start_time: '20:00:00', end_time: '20:20:00' },
			{ id: 1, start_time: '20:20:00', end_time: '20:40:00' },
			{ id: 2, start_time: '20:40:00', end_time: '21:00:00' },
		],
	},
	{
		id: 21,
		start_time: '21:00:00',
		end_time: '22:00:00',
		timings: [
			{ id: 0, start_time: '21:00:00', end_time: '21:20:00' },
			{ id: 1, start_time: '21:20:00', end_time: '21:40:00' },
			{ id: 2, start_time: '21:40:00', end_time: '22:00:00' },
		],
	},
	{
		id: 22,
		start_time: '22:00:00',
		end_time: '23:00:00',
		timings: [
			{ id: 0, start_time: '22:00:00', end_time: '22:20:00' },
			{ id: 1, start_time: '22:20:00', end_time: '22:40:00' },
			{ id: 2, start_time: '22:40:00', end_time: '23:00:00' },
		],
	},
	{
		id: 23,
		start_time: '23:00:00',
		end_time: '24:00:00',
		timings: [
			{ id: 0, start_time: '23:00:00', end_time: '23:20:00' },
			{ id: 1, start_time: '23:20:00', end_time: '23:40:00' },
			{ id: 2, start_time: '23:40:00', end_time: '24:00:00' },
		],
	},
];
